
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_1_0__64bugsnag_43core_647_25_0_esbuild_640_23_1_magicast_640_3_5_nuxt_643_12_65v4daeqhwe2li7dypvqjjc6qe_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_4_0__64bugsnag_43core_647_25_0_esbuild_640_23_1_graphql_ws_645_16_0_graph_uoxejqb3feizny6ezlmtxylsfi_node_modules__64bttr_devs_nuxt_layer_sofie_locales_en_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_4_1__64bugsnag_43core_647_25_0_encoding_640_1_13_esbuild_640_23_1_gr_danhkhhha2oeomy2xzcjdi4vcy_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_en_json" */), cache: true }],
  "nl": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_1_0__64bugsnag_43core_647_25_0_esbuild_640_23_1_magicast_640_3_5_nuxt_643_12_65v4daeqhwe2li7dypvqjjc6qe_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_4_0__64bugsnag_43core_647_25_0_esbuild_640_23_1_graphql_ws_645_16_0_graph_uoxejqb3feizny6ezlmtxylsfi_node_modules__64bttr_devs_nuxt_layer_sofie_locales_nl_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json" /* webpackChunkName: "locale__data_www_mijn_khn_nl_mijn_khn_releases_40_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_4_1__64bugsnag_43core_647_25_0_encoding_640_1_13_esbuild_640_23_1_gr_danhkhhha2oeomy2xzcjdi4vcy_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_nl_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "files": [
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json",
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json",
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json"
      ]
    },
    {
      "code": "nl",
      "files": [
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json",
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json",
        "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      },
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json"
      },
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_65v4daeqhwe2li7dypvqjjc6qe/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      },
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_uoxejqb3feizny6ezlmtxylsfi/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json"
      },
      {
        "path": "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_esbuild@0.23.1_gr_danhkhhha2oeomy2xzcjdi4vcy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
