import { default as _91_46_46_46slug_93A99JL5TGnYMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/[...slug].vue?macro=true";
import { default as adressen2CUjmOhpo0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexOOlTP1PdnOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersBz1ZwoajCxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexCWPokVD87OMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexYwOqznPxIWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexOYtxg9e7hKMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93NUX6PKGD6DMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexXe9F3eTngiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/facturen/index.vue?macro=true";
import { default as indexpBkfOpDJbeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/index.vue?macro=true";
import { default as indexZN5W9I1LPDMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassoHSYyqb42VxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexfdKNZrdzCkMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexC7avDE0dWUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenKaNU4UxBcvMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accounttjAh41pGQTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account.vue?macro=true";
import { default as login2pKiVFEyeTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93Yd3wMTd6cPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93xwahigpCqfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexYtkPiwxHluMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_93d8opkNhqrtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademiePep47AqMufMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenDjk4xy0PvOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexlE1Yf9leWtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersY7ITf22xFKMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders.vue?macro=true";
import { default as dashboardqEQdnqKw25Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/dashboard.vue?macro=true";
import { default as aanmeldenssQQcq4GNBMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/[slug]/aanmelden.vue?macro=true";
import { default as indexterdWyAVsKMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/[slug]/index.vue?macro=true";
import { default as index3RWYd0ybY5Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/index.vue?macro=true";
import { default as evenementent5BQJdZokZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen.vue?macro=true";
import { default as indexnHzETI1sWXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/index.vue?macro=true";
import { default as kennisbankPDZV5QxkVJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/kennisbank.vue?macro=true";
import { default as _91id_93K0cQvUJxOyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/klanten/[id].vue?macro=true";
import { default as indexFG9sbhBcWpMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/klanten/index.vue?macro=true";
import { default as afnemenAZGeApwuiOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexlEIcPiiTttMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexHJzLKmrbhzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelent7jV3gXcggMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen.vue?macro=true";
import { default as index6yo6dFYNkkMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvendze61RbhOeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizeB1tRx0UPDXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as styleswzJpQKEWbjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/styles.vue?macro=true";
import { default as _91id_93xszqLYJrFEMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/verkoopkansen/[id].vue?macro=true";
import { default as index1EaU70MniyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93MHd1C9T4PGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexsriSG8b8PrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/[category]/index.vue?macro=true";
import { default as cart7PVLRueMk8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/cart.vue?macro=true";
import { default as checkoutwg7BX3a7EeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/checkout.vue?macro=true";
import { default as confirmationGdwc4rCLXpMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/confirmation.vue?macro=true";
import { default as index4ZjkS7UMKwMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/index.vue?macro=true";
import { default as webshopfhyehmvb3QMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop.vue?macro=true";
import { default as zoekenlmClfIxKi5Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/zoeken.vue?macro=true";
import { default as indexD704OlaQYNMeta } from "~/pages/evenementen/[slug]/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93A99JL5TGnYMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accounttjAh41pGQTMeta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/bedrijfsgegevens/index.vue").then(m => m.default || m)
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/communicatievoorkeuren/index.vue").then(m => m.default || m)
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/facturen/[factuur].vue").then(m => m.default || m)
  },
  {
    name: "account-facturen",
    path: "facturen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/facturen/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    meta: indexpBkfOpDJbeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/ledenvoordelen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/lidmaatschap/index.vue").then(m => m.default || m)
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenKaNU4UxBcvMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/account/nieuwsbriefvoorkeuren.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login2pKiVFEyeTMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93Yd3wMTd6cPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93xwahigpCqfMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: indexYtkPiwxHluMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/auth/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: bestuurdersY7ITf22xFKMeta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders.vue").then(m => m.default || m),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_93d8opkNhqrtMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-bestuurdersacademie",
    path: "bestuurdersacademie",
    meta: bestuurdersacademiePep47AqMufMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/bestuurdersacademie.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenDjk4xy0PvOMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/dossiers-documenten.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexlE1Yf9leWtMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/bestuurders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: evenementent5BQJdZokZMeta?.name,
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen.vue").then(m => m.default || m),
    children: [
  {
    name: "evenementen-slug-aanmelden",
    path: ":slug()/aanmelden",
    meta: aanmeldenssQQcq4GNBMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/[slug]/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: "evenementen-slug",
    path: ":slug()",
    meta: indexterdWyAVsKMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "evenementen",
    path: "",
    meta: index3RWYd0ybY5Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/evenementen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexnHzETI1sWXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/kennisbank.vue").then(m => m.default || m)
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_93K0cQvUJxOyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/klanten/[id].vue").then(m => m.default || m)
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: indexFG9sbhBcWpMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: ledenvoordelent7jV3gXcggMeta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen.vue").then(m => m.default || m),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenAZGeApwuiOMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexlEIcPiiTttMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexHJzLKmrbhzMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/ledenvoordelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: index6yo6dFYNkkMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/lid-worden/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvendze61RbhOeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/nieuwsbrief/uitschrijven.vue").then(m => m.default || m)
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizeB1tRx0UPDXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93xszqLYJrFEMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/verkoopkansen/[id].vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: index1EaU70MniyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/verkoopkansen/index.vue").then(m => m.default || m)
  },
  {
    name: webshopfhyehmvb3QMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop.vue").then(m => m.default || m),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93MHd1C9T4PGMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexsriSG8b8PrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cart7PVLRueMk8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/cart.vue").then(m => m.default || m)
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutwg7BX3a7EeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationGdwc4rCLXpMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "webshop",
    path: "",
    meta: index4ZjkS7UMKwMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    meta: zoekenlmClfIxKi5Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/40/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-evenementen-slug",
    path: "/bestuurders/evenementen/:slug()",
    meta: indexD704OlaQYNMeta || {},
    component: () => import("~/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  }
]